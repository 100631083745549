import React from 'react';
import { CButton, CLabel } from '@coreui/react';
import _t from 'counterpart';

interface IInputHeaderProps {
	labelText: string;
	resetFilter?: () => void;
}

const InputHeader = ({ labelText, resetFilter }: IInputHeaderProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
			{resetFilter && (
				<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFilter}>
					{_t.translate('action.reset').toUpperCase()}
				</CButton>
			)}
		</div>
	);
};

export default React.memo(InputHeader);
