import React, { useMemo, useState } from 'react';
import _t from 'counterpart';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import {
	disableWallet,
	enableWallet,
	fetchWallet,
	updatePropAccountProgressStatus,
} from '../../services/BackendService';
import PageLayout from '../../components/PageLayout';
import {
	CBadge,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CLink,
	CRow,
} from '@coreui/react';
import Loading from '../../components/Loading';
import Balance from '../customers/CustomerWalletBalance';
import Credit from '../customers/CustomerWalletCredit';
import PerformanceChart from './PerformanceChart';
import Error from '../../components/Error';
import ConfirmationModal from '../../components/ConfirmationModal';
import toast from 'react-hot-toast';
import { TradingType, WalletType } from '../customers/types';
import PropAccountChallengeInfo from './PropAccountObjectives';
import PropFundedAccountInfo from './PropFundedAccountInfo';
import { IFundedAccountWithBalance, IPropChallengeAccount, PropChallengeAccountStatus } from './types';
import { IChallengeLimit, IChallengeObjective } from '../propTrading/types';
import { useLiveAccountUpdates } from '../../helpers/customHooks';

export interface IPropChallengeData {
	propChallengeId: number;
	propStatus: PropChallengeAccountStatus;
	walletLimits: Array<IChallengeLimit>;
	walletObjectives: Array<IChallengeObjective>;
}

const WalletPage = () => {
	const [showEnableWallet, setShowEnableWallet] = useState<boolean>(false);
	const [showDisableWallet, setShowDisableWallet] = useState<boolean>(false);
	const [showPassChallenge, setShowPassChallenge] = useState<boolean>(false);
	const [showFailChallenge, setShowFailChallenge] = useState<boolean>(false);

	const queryClient = useQueryClient();
	const params = useParams();
	const { id } = params as any;

	useLiveAccountUpdates(id);

	const {
		data: walletData,
		isLoading,
		isIdle,
		isError,
		refetch,
	} = useQuery(['customer-wallet', id], () => fetchWallet(id), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const enableWalletMutation = useMutation(() => enableWallet(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['customer-wallet', id]);
			setShowEnableWallet(false);
			toast.success(_t('wallet.wallet-enabled'));
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const disableWalletMutation = useMutation(() => disableWallet(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['customer-wallet', id]);
			setShowDisableWallet(false);
			toast.success(_t('wallet.wallet-disabled'));
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const updatePropChallengeProgressStatusMutation = useMutation(
		(status: string) => updatePropAccountProgressStatus(id, status),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['customer-wallet', id]);
				queryClient.invalidateQueries(['prop-challenge-account-progress']);
				toast.success(_t('wallet.challenge-status-updated'));
			},
			onError: (e: any) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const handleModalVisibility = useMemo(
		() => ({
			enable: {
				show: () => setShowEnableWallet(true),
				hide: () => setShowEnableWallet(false),
			},
			disable: {
				show: () => setShowDisableWallet(true),
				hide: () => setShowDisableWallet(false),
			},
			passChallenge: {
				show: () => setShowPassChallenge(true),
				hide: () => setShowPassChallenge(false),
				confirm: () => {
					updatePropChallengeProgressStatusMutation.mutate(PropChallengeAccountStatus.Passed);
					setShowPassChallenge(false);
				},
			},
			failChallenge: {
				show: () => setShowFailChallenge(true),
				hide: () => setShowFailChallenge(false),
				confirm: () => {
					updatePropChallengeProgressStatusMutation.mutate(PropChallengeAccountStatus.Failed);
					setShowFailChallenge(false);
				},
			},
		}),
		[
			setShowEnableWallet,
			setShowDisableWallet,
			setShowPassChallenge,
			setShowFailChallenge,
			updatePropChallengeProgressStatusMutation,
		]
	);

	if (isLoading || isIdle || !walletData) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const { wallet, history, limits, objectives } = walletData;

	const calculateMarginLevel = () => {
		if (wallet.equity === null) {
			return null;
		}
		if (Number(wallet.margin) === 0) {
			return 0;
		}
		return Number(wallet.equity) / Number(wallet.margin);
	};

	return (
		<PageLayout
			title={_t('wallet.title')}
			titleAppend={
				<div className="d-flex justify-content-between float-right mb-2">
					<div className="d-flex flex-nowrap flex-row-reverse">
						<CDropdown className="float-right">
							<CDropdownToggle caret color="primary">
								{_t('wallet.options')}
							</CDropdownToggle>
							<CDropdownMenu>
								{wallet.status === 'DISABLED' && (
									<CDropdownItem onClick={handleModalVisibility.enable.show}>
										{_t('wallet.enable-wallet')}
									</CDropdownItem>
								)}
								{wallet.status === 'ACTIVE' && (
									<CDropdownItem onClick={handleModalVisibility.disable.show}>
										{_t('wallet.disable-wallet')}
									</CDropdownItem>
								)}
								{wallet.status !== 'DISABLED' && wallet.type === WalletType.PropChallenge && (
									<CDropdownItem onClick={handleModalVisibility.passChallenge.show}>
										{_t('wallet.pass-challenge')}
									</CDropdownItem>
								)}
								{wallet.status !== 'DISABLED' && wallet.type === WalletType.PropChallenge && (
									<CDropdownItem onClick={handleModalVisibility.failChallenge.show}>
										{_t('wallet.fail-challenge')}
									</CDropdownItem>
								)}
							</CDropdownMenu>
						</CDropdown>
					</div>
				</div>
			}
		>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.info')}</CCardTitle>
							<div className="float-right">
								<CBadge style={{ backgroundColor: wallet.tradingType === TradingType.Netting ? '#18B8C2' : '#9618C2' }}>
									{wallet.tradingType === TradingType.Netting ? _t('wallets.netting') : _t('wallets.hedging')}
								</CBadge>
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('global.status')}:</dt>
										<dd>
											<CBadge color={wallet.status.toLowerCase()}>{wallet.status}</CBadge>
											{wallet.primary && <br />}
											{wallet.primary && <CBadge color="primary">PRIMARY</CBadge>}
										</dd>
										<dt>{_t('customer.wallets.type')}:</dt>
										<dd>{wallet.type}</dd>

										<dt>{_t('customer.wallets.accountId')}:</dt>
										<dd>{wallet.id}</dd>

										<dt>{_t('customer.wallets.currency')}:</dt>
										<dd>{wallet.currency}</dd>

										<dt>{_t('customer.wallets.balance')}:</dt>
										<dd>{wallet.balance ? <Balance value={wallet.balance} currency={wallet.currency} /> : 'n/a'}</dd>

										<dt>{_t('wallet.margin')}:</dt>
										<dd>{formatCurrency(wallet.margin, wallet.currency)}</dd>

										<dt>{_t('wallet.profit-loss')}:</dt>
										<dd>
											{wallet.profitLoss === undefined
												? _t('wallet.no-open-positions')
												: formatCurrency(wallet.profitLoss, wallet.currency)}
										</dd>

										{wallet.propFunnelId && wallet.type === WalletType.PropFunded && (
											<>
												<dt>{_t('wallet.trader-profit-share-factor')}:</dt>
												<dd>{Number((wallet as IFundedAccountWithBalance).traderProfitShareFactor) * 100}%</dd>
											</>
										)}
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('customer.wallets.credit')}:</dt>
										<dd>{wallet.credit ? <Credit value={wallet.credit} currency={wallet.currency} /> : 'n/a'}</dd>

										<dt>{_t('wallet-modal.group')}:</dt>
										<dd>
											<CLink href={`/groups/${wallet.group.id}`}>{wallet.group.name}</CLink>
										</dd>

										<dt>{_t('wallet.equity')}:</dt>
										<dd>{formatCurrency(wallet.equity, wallet.currency)}</dd>

										<dt>{_t('wallet.free-margin')}:</dt>
										<dd>{formatCurrency(wallet.marginFree, wallet.currency)}</dd>

										<dt>{_t('wallet.margin-level')}:</dt>
										<dd>{calculateMarginLevel()?.toFixed(2)}</dd>

										{wallet.propFunnelId && (
											<>
												<dt>{_t('wallet.prop-funnel-id')}:</dt>
												<dd>
													<CLink href={`/prop-funnels/${wallet.propFunnelId}`}>{wallet.propFunnelId}</CLink>
												</dd>
											</>
										)}

										{wallet.propFunnelId && wallet.type === WalletType.PropFunded && (
											<>
												<dt>{_t('wallet.funded-account-behavior')}:</dt>
												<dd>{(wallet as IFundedAccountWithBalance).behavior}</dd>
											</>
										)}
										{wallet.propFunnelId && wallet.type === WalletType.PropFunded && (
											<>
												<dt>{_t('wallet.available-withdrawal-funds')}:</dt>
												<dd>
													{formatCurrency(
														(wallet as IFundedAccountWithBalance).availableWithdrawalFunds,
														wallet.currency
													)}
												</dd>
											</>
										)}
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('wallet.wallet-performance')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<PerformanceChart data={history} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{wallet.type === WalletType.PropChallenge && limits && objectives && (
				<PropAccountChallengeInfo
					propChallengeId={(wallet as IPropChallengeAccount).propChallengeId}
					propStatus={(wallet as IPropChallengeAccount).propStatus}
					walletLimits={limits}
					walletObjectives={objectives}
				/>
			)}
			{wallet.type === WalletType.PropFunded && limits && objectives && (
				<PropFundedAccountInfo limits={limits} objectives={objectives} />
			)}
			<ConfirmationModal
				id={id}
				show={showEnableWallet}
				onConfirm={enableWalletMutation.mutate}
				hidePanel={handleModalVisibility.enable.hide}
				title={_t('wallet.enable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={enableWalletMutation.isLoading}
			/>
			<ConfirmationModal
				id={id}
				show={showDisableWallet}
				onConfirm={disableWalletMutation.mutate}
				hidePanel={handleModalVisibility.disable.hide}
				title={_t('wallet.disable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={disableWalletMutation.isLoading}
			/>
			<ConfirmationModal
				id={id}
				show={showPassChallenge}
				onConfirm={handleModalVisibility.passChallenge.confirm}
				hidePanel={handleModalVisibility.passChallenge.hide}
				title={_t('wallet.pass-challenge-confirmation')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={updatePropChallengeProgressStatusMutation.isLoading}
			/>
			<ConfirmationModal
				id={id}
				show={showFailChallenge}
				onConfirm={handleModalVisibility.failChallenge.confirm}
				hidePanel={handleModalVisibility.failChallenge.hide}
				title={_t('wallet.fail-challenge-confirmation')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={updatePropChallengeProgressStatusMutation.isLoading}
			/>
		</PageLayout>
	);
};

export default WalletPage;
