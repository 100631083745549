import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CRow } from '@coreui/react';
import { Link, useParams } from 'react-router-dom';
import { loadCustomer, loadTransaction, refundWithdrawal } from '../services/BackendService';
import { TransactionStatusBadge } from './transactions/TransactionStatusBadge';
import { extractErrorMessage, formatCurrency, formatDateTime, getIconNameForCountry } from '../helpers';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { ITransaction, TransactionStatus } from './transactions/types';
import CIcon from '@coreui/icons-react';
import Loading from '../components/Loading';
import Error from '../components/Error';
import PageLayout from '../components/PageLayout';
import ButtonWrapper from '../components/ButtonWrapper';
import UpdateWithdrawalStateModal from './transactions/UpdateWithdrawalStateModal';
import WithdrawalReviewStatusBadge from './transactions/WithdrawalReviewStatusBadge';
import toast from 'react-hot-toast';
import InlineSpinner from '../components/InlineSpinner';

const TransactionPage = () => {
	const params = useParams();

	const { id } = params as any;

	interface ISelectedWithdrawal {
		withdrawal: ITransaction;
		tableActionType: 'approve' | 'reject';
	}

	const [refundedWithdrawal, setRefundedWithdrawal] = useState<string | null>(null);
	const [selectedWithdrawal, setSelectedWithdrawal] = useState<ISelectedWithdrawal | null>(null);

	const loadTransactionQuery = useQuery(['transaction', id], () => loadTransaction(id || ''), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
		enabled: Boolean(id),
	});

	const transaction = loadTransactionQuery.data;
	const withdrawalReviewerId = transaction?.withdrawalReview?.reviewerId;

	const withdrawalReviewerQuery = useQuery(['transaction-reviewer', withdrawalReviewerId], () => loadCustomer(withdrawalReviewerId!), {
		enabled: Boolean(withdrawalReviewerId),
		retry: false,
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(`Error loading reviewer: ${error}`);
		},
	});

	const refundMutation = useMutation(['refund'], (transactionId: string) => refundWithdrawal(transactionId), {
		onSuccess: () => {
			toast.success(_t('transactions.successful-refund'));
			loadTransactionQuery.refetch();
		},
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			toast.error(msg);
		},
	});

	const showWithdrawalModal = (tableActionType: 'approve' | 'reject', withdrawal: ITransaction) => {
		setSelectedWithdrawal({ withdrawal, tableActionType });
	};

	const refund = (transactionId: string) => {
		if (!transactionId) return;
		setRefundedWithdrawal(transactionId);
		refundMutation.mutate(transactionId);
	};

	const onWithdrawalChangeStateClose = (refetchData: boolean) => {
		if (refetchData) {
			loadTransactionQuery.refetch();
		}
		setSelectedWithdrawal(null);
	};

	const getCountryIcon = (countryCode: string) => {
		if (!countryCode) return null;
		return <CIcon name={getIconNameForCountry(countryCode.toLowerCase())} size="xl" title={countryCode} />;
	};

	if (loadTransactionQuery.isLoading) {
		return <Loading />;
	}

	if (loadTransactionQuery.isError) {
		return <Error onRetry={loadTransactionQuery.refetch} />;
	}

	if (!transaction) {
		return <Error onRetry={loadTransactionQuery.refetch} />;
	}

	return (
		<PageLayout
			title={_t.translate('transaction.title')}
			titleAppend={
				<CRow className="float-right">
					{transaction.type === 'WITHDRAWAL' && transaction.status === TransactionStatus.Pending && (
						<>
							<CButton onClick={() => showWithdrawalModal('approve', transaction)} className="mr-2" color="success">
								{_t('action.approve')}
							</CButton>
							<CButton onClick={() => showWithdrawalModal('reject', transaction)} className="mr-2" color="danger">
								{_t('action.reject')}
							</CButton>
						</>
					)}
					{transaction.type === 'WITHDRAWAL' && transaction.status === TransactionStatus.Successful && (
						<ButtonWrapper
							isLoading={refundMutation.isLoading && refundedWithdrawal === transaction.id}
							onClick={() => refund(transaction.id)}
							className="mr-1"
							color="primary"
						>
							{_t('action.refund')}
						</ButtonWrapper>
					)}
				</CRow>
			}
		>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.details')}</CCardTitle>
							<div className="float-right">
								<TransactionStatusBadge status={transaction.status || TransactionStatus.Failed} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('transaction.transaction-id')}:</dt>
										<dd>{id}</dd>

										<dt>{_t('global.customer')}</dt>
										<dd>
											{transaction.customer && (
												<Link to={`/customers/${transaction.customer.id}`}>{transaction.customer.name}</Link>
											)}
											{!transaction.customer && '-'}
										</dd>

										<dt>{_t('transactions.wallet')}:</dt>
										<dd>
											{transaction.wallet && <Link to={`/wallets/${transaction.wallet}`}>{transaction.wallet}</Link>}
											{!transaction.wallet && '-'}
										</dd>

										<dt>{_t('global.amount')}:</dt>
										<dd>{formatCurrency(transaction.amount, transaction.currency)}</dd>

										<dt>{_t('global.net-amount')} USD:</dt>
										<dd>{formatCurrency(transaction.netAmountInUSD || 0)}</dd>

										<dt>{_t('transactions.ip-address')}:</dt>
										<dd>
											{transaction.ipAddress || '-'}&nbsp;
											{transaction.countryCode && getCountryIcon(transaction.countryCode)}
										</dd>

										{transaction.withdrawalReview && (
											<>
												<dt>{_t('transaction.admin-ip-address')}:</dt>
												<dd>
													{transaction.withdrawalReview.reviewerIp || '-'}&nbsp;
													{transaction.reviewerCountryCode && getCountryIcon(transaction.reviewerCountryCode)}
												</dd>
											</>
										)}
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('global.method')}:</dt>
										<dd>{transaction.method || 'Internal'}</dd>

										<dt>{_t('global.type')}:</dt>
										<dd>{transaction.type || '-'}</dd>

										<dt>{_t('global.provider')}:</dt>
										<dd>{transaction.provider || 'Internal'}</dd>

										<dt>{_t('global.provider-reference')}:</dt>
										<dd>{transaction.providerRef || _t('global.no-value')}</dd>

										<dt>{_t('global.comment')}:</dt>
										<dd>{transaction.comment || '-'}</dd>

										{transaction.type === 'WITHDRAWAL' && (
											<>
												<dt>{_t('global.reviewed-by')}:</dt>
												<dd>
													{!withdrawalReviewerId && '-'}
													{withdrawalReviewerId && withdrawalReviewerQuery.isSuccess ? (
														<Link to={`/customers/${withdrawalReviewerQuery.data.id}`}>{withdrawalReviewerQuery.data.name}</Link>
													) : (
														<InlineSpinner />
													)}
												</dd>

												<dt>{_t('global.review-status')}:</dt>
												<dd>
													<WithdrawalReviewStatusBadge status={transaction.withdrawalReview?.status || null} />
												</dd>
											</>
										)}
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('transactions.exchange-rate-details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('transactions.conversion.from')}:</dt>
										<dd>{transaction.conversionFrom || '-'}</dd>

										<dt>{_t('transactions.conversion.from-amount')}:</dt>
										<dd>
											{transaction.conversionFrom
												? formatCurrency(transaction.conversionFromAmount, transaction.conversionFrom)
												: '-'}
										</dd>

										<dt>{_t('transactions.conversion.rate')}:</dt>
										<dd>{transaction.conversionRate ? Number(transaction.conversionRate).toFixed(2) : '-'}</dd>

										<dt>{_t('transactions.conversion.date')}:</dt>
										<dd>{transaction.conversionDate ? formatDateTime(transaction.conversionDate) : '-'}</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('transactions.conversion.to')}:</dt>
										<dd>{transaction.conversionTo || '-'}</dd>

										<dt>{_t('transactions.conversion.to-amount')}:</dt>
										<dd>
											{transaction.conversionTo
												? formatCurrency(transaction.conversionToAmount, transaction.conversionTo)
												: '-'}
										</dd>

										<dt>{_t('transactions.conversion.source')}:</dt>
										<dd>{transaction.conversionSource || '-'}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{selectedWithdrawal && (
				<UpdateWithdrawalStateModal
					show={Boolean(selectedWithdrawal)}
					onClose={onWithdrawalChangeStateClose}
					actionType={selectedWithdrawal.tableActionType}
					withdrawal={selectedWithdrawal.withdrawal}
				/>
			)}
		</PageLayout>
	);
};

export default TransactionPage;
