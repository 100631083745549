import React from 'react';
import { CCard, CCardBody, CCol, CLink, CRow } from '@coreui/react';
import { formatDateTime } from '../../helpers';
import _t from 'counterpart';

interface LiveFeedEntryProps {
	eventData: IPendingOrderEvent;
}

export interface IPendingOrderEvent {
	id: number;
	accountId: string;
	customerId: string;
	symbol: string;
	side: string;
	type: string;
	placedAt: string;
}

const LiveFeedEntryCreatedOrders: React.FC<LiveFeedEntryProps> = ({ eventData }) => {
	return (
		<CCard className="mb-2">
			<CCardBody>
				<CRow>
					<CCol xs="12" md="6">
						<h6 className="card-title">
							{_t.translate('dashboard.created-order')}
							<CLink href={`/orders/${eventData.id}`}> {` #${eventData.id}`}</CLink>
						</h6>
					</CCol>
					<CCol xs="12" md="6">
						<div className="text-end dashboard-text-muted">{formatDateTime(eventData.placedAt)}</div>
					</CCol>
				</CRow>
				<CRow className="mt-1">
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.account-id')}</small>
							<div>{<CLink href={`/wallets/${eventData.accountId}`}>{eventData.accountId}</CLink>}</div>
						</div>
					</CCol>
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.symbol')}</small>
							<div>{eventData.symbol}</div>
						</div>
					</CCol>
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('orders.side')}</small>
							<div>{eventData.side}</div>
						</div>
					</CCol>
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('global.type')}</small>
							<div>{eventData.type}</div>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};

export default LiveFeedEntryCreatedOrders;
