import React from 'react';
import { CBadge } from '@coreui/react';
import _t from 'counterpart';
import { WithdrawalStatus } from './types';

interface IProps {
	status: WithdrawalStatus | null;
}

const statusToColor = {
	[WithdrawalStatus.Approved]: 'success',
	[WithdrawalStatus.Refunded]: 'secondary',
	[WithdrawalStatus.Rejected]: 'danger',
};

const WithdrawalReviewStatusBadge = ({ status }: IProps) => {
	if (!status) {
		return <CBadge color="warning">{_t(`global.transaction-status.pending`).toUpperCase()}</CBadge>;
	}

	const color = statusToColor[status];
	return <CBadge color={color}>{_t(`withdrawal.status.${status}`).toUpperCase()}</CBadge>;
};

export default React.memo(WithdrawalReviewStatusBadge);
