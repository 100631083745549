import React from 'react';
import { CCard, CCardBody, CCol, CLink, CRow } from '@coreui/react';
import { formatCurrency, formatDateTime } from '../../helpers';
import _t from 'counterpart';

interface LiveFeedEntryProps {
	eventData: ISignupEvent;
}

export interface ISignupEvent {
	id: string;
	accountId: string;
	customerId: string;
	name: number;
	country: string;
	createdAt: string;
}

const LiveFeedEntrySignup: React.FC<LiveFeedEntryProps> = ({ eventData }) => {
	return (
		<CCard className="mb-2">
			<CCardBody>
				<CRow>
					<CCol xs="12" md="6">
						<h6 className="card-title">
							{_t.translate('dashboard.signup')}
							<CLink href={`/customers/${eventData.id}`}>{` #${eventData.id.substring(0, 7)}`}</CLink>
						</h6>
					</CCol>
					<CCol xs="12" md="6">
						<div className="text-end dashboard-text-muted">{formatDateTime(eventData.createdAt)}</div>
					</CCol>
				</CRow>
				<CRow className="mt-1">
					<CCol xs={eventData.accountId ? 3 : 1}>
						<div>
							<small className="dashboard-text-muted">
								{eventData.accountId ? _t.translate('dashboard.account-id') : ''}
							</small>
							<div>
								{eventData.accountId ? (
									<CLink href={`/wallets/${eventData.accountId}`}>{eventData.accountId}</CLink>
								) : (
									''
								)}
							</div>
						</div>
					</CCol>
					<CCol xs="6">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.customer-name')}</small>
							<div>
								<CLink href={`/customers/${eventData.customerId}`}>{eventData.name}</CLink>
							</div>
						</div>
					</CCol>
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.country')}</small>
							<div>{eventData.country}</div>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};
export default LiveFeedEntrySignup;
