import React, { useEffect, useState } from 'react';
import {
	CButton,
	CFormGroup,
	CInput,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CSelect,
	CSwitch,
} from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrderGroupsSelector } from './CustomerWalletPopup';
import { createWallet, getPropFunnels, loadGroups } from '../../services/BackendService';
import { CreateWalletType, ICreateWallet, TradingType, WalletType } from './types';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import CenteredSpinner from '../../components/CenteredSpinner';
import NetworkError from '../../components/NetworkError';
import { AxiosError } from 'axios';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import toast from 'react-hot-toast';
import { IGroup } from '../groups/types';
import { IPropFunnel } from '../propTrading/propFunnels/types';

//Fix me: part of the code temporarily commented out - it's implementation for propFunded and propChallenge account creation that we still have to define first

interface IProps {
	show: boolean;
	onClose: () => void;
	customerId: string;
}

const AddWalletModal = (props: IProps) => {
	const queryClient = useQueryClient();
	const { show, onClose, customerId } = props;

	const [groupId, setGroupId] = useState<number | undefined>();
	const [leverage, setWalletLeverage] = useState<string>('');
	const [isDemoWallet, toggleIsDemoWallet] = useState<boolean>(false);
	const [tradingType, setTradingType] = useState<TradingType>(TradingType.Hedging);
	const [type, setWalletType] = useState<CreateWalletType>(CreateWalletType.Trading);
	const [propFunnelId, setPropFunnelId] = useState<string>();

	const groupsQuery = useQuery<Array<IGroup>, AxiosError>(['order-groups'], () => loadGroups());

	useEffect(() => {
		if (groupsQuery.data && groupId && groupsQuery.data.length > 0) {
			const group = groupsQuery.data.find((group: any) => group.id === groupId);
			if (group?.leverage) {
				setWalletLeverage(group.leverage.toString());
			}
		}

		if (propFunnelsQuery.data && propFunnelsQuery.data.length > 0 && !propFunnelId) {
			setPropFunnelId(propFunnelsQuery.data[0].id);
		}
	}, [groupId, groupsQuery.data]);

	const createNewWalletMutation = useMutation((wallet: ICreateWallet) => createWallet(wallet), {
		onSuccess: async () => {
			await queryClient.invalidateQueries(['customer-wallets']);
			onClose();
		},
		onError: async (e: any) => {
			if (e.response?.status !== 422) {
				const msg = extractErrorMessage(e);
				toast.error(msg);
			}
		},
	});

	const propFunnelsQuery = useQuery<Array<IPropFunnel>, AxiosError>(['get-prop-funnels'], () => getPropFunnels());

	const tryCreateWallet = () => {
		if (type === CreateWalletType.PropTrading) {
			if (propFunnelId) {
				createNewWalletMutation.mutate({
					customerId,
					funnelId: propFunnelId,
				});
			}
		} else {
			const leverageToNumber = Number(leverage);
			createNewWalletMutation.mutate({
				customerId,
				groupId,
				leverage: leverageToNumber,
				isDemo: isDemoWallet,
				tradingType,
			});
		}
	};

	const onWalletLeverageChanged = (event: any) => {
		setWalletLeverage(event.target.value);
	};

	const onTradingTypeChanged = (event: any) => {
		setTradingType(event.target.value);
	};

	const onWalletTypeChanged = (event: any) => {
		setWalletType(event.target.value);
	};

	const onPropFunnelChanged = (event: any) => {
		setPropFunnelId(event.target.value);
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(createNewWalletMutation.error, paramName);
	};

	const onOpen = () => {
		setGroupId(undefined);
		createNewWalletMutation.reset();
		toggleIsDemoWallet(false);
	};

	const toggleIsDemo = () => {
		toggleIsDemoWallet(!isDemoWallet);
	};

	return (
		<>
			<CModal show={show} onOpened={onOpen} onClose={onClose} closeOnBackdrop={false}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('customer.add-wallet.title')}</CModalTitle>
				</CModalHeader>
				<CModalBody>
					{groupsQuery.isLoading && <CenteredSpinner color="primary" />}
					{groupsQuery.isError && <NetworkError error={groupsQuery.error} />}
					{Boolean(groupsQuery.data) && (
						<>
							<CFormGroup>
								<CLabel htmlFor="nf-wallet-type">{_t('wallet-modal.wallet-type')}</CLabel>
								<CSelect value={type} onChange={onWalletTypeChanged}>
									<option value={CreateWalletType.Trading} key={CreateWalletType.Trading}>
										{CreateWalletType.Trading}
									</option>
									<option value={CreateWalletType.PropTrading} key={CreateWalletType.PropTrading}>
										{CreateWalletType.PropTrading}
									</option>
								</CSelect>
							</CFormGroup>
							{type === CreateWalletType.PropTrading && (
								<CFormGroup>
									<CLabel htmlFor="nf-prop-funnel">{_t('wallet-modal.prop-funnel')}</CLabel>
									<CSelect onChange={onPropFunnelChanged}>
										{propFunnelsQuery.data?.map((funnel) => (
											<option value={funnel.id} key={funnel.id}>
												{funnel.id}
											</option>
										))}
									</CSelect>
								</CFormGroup>
							)}
							{type === CreateWalletType.Trading && (
								<>
									<CFormGroup>
										<CLabel htmlFor="nf-group">{_t('wallet-modal.group')}</CLabel>
										<OrderGroupsSelector onChange={setGroupId} value={groupId} currency="USD" />
										{findError('groupId') && <CLabel className="text-danger">{findError('groupId')}</CLabel>}
										<CLabel htmlFor="nf-type">{_t('wallet-modal.type')}</CLabel>
										<CSelect value={tradingType} onChange={onTradingTypeChanged}>
											<option value={TradingType.Hedging} key={TradingType.Hedging}>
												{TradingType.Hedging}
											</option>
											<option value={TradingType.Netting} key={TradingType.Netting}>
												{TradingType.Netting}
											</option>
										</CSelect>
										{findError('groupId') && <CLabel className="text-danger">{findError('groupId')}</CLabel>}
									</CFormGroup>
									<CFormGroup>
										<CLabel htmlFor="nf-leverage">{_t('customer.wallets.set-wallet-leverage')}</CLabel>
										<CInput
											id="nf-leverage"
											name="nf-leverage"
											placeholder="300"
											value={leverage}
											onChange={onWalletLeverageChanged}
										/>
										{findError('leverage') && <CLabel className="text-danger">{findError('leverage')}</CLabel>}
									</CFormGroup>
									<div className="d-flex align-items-center mb-3">
										<CSwitch
											id="send-all"
											color="primary"
											variant="3d"
											onChange={toggleIsDemo}
											checked={isDemoWallet}
										/>
										<span className="ml-2">{_t.translate('wallet-modal.is-demo')}</span>
									</div>
								</>
							)}
						</>
					)}
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={createNewWalletMutation.isLoading}
						onClick={tryCreateWallet}
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t.translate('action.add')}
						className="mr-2"
					/>
					<CButton color="light" variant="outline" onClick={onClose} disabled={createNewWalletMutation.isLoading}>
						{_t('action.cancel')}
					</CButton>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default AddWalletModal;
