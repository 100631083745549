import React, { useState, useEffect, FormEvent } from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CForm,
	CFormGroup,
	CLabel,
	CInputGroup,
	CInput,
	CSwitch,
	CInputGroupPrepend,
	CInputGroupText,
} from '@coreui/react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import {
	createPropFunnel,
	updatePropFunnel,
	getPropFunnel,
	getPropTradingChallenges,
	getFundedAccountTypes,
} from '../../../services/BackendService';
import { findErrorFromValidation, errorWithCode } from '../../../helpers';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import PageLayout from '../../../components/PageLayout';
import toast from 'react-hot-toast';
import { ICreatePropFunnelFormData, IPropFunnel, IUpdatePropFunnelFormData } from './types';
import IPropChallenge from '../types';
import { IFundedAccountType } from '../fundedAccountType/types';
import CustomSelect from '../../../components/CustomSelect';
import InputHeader from '../../../components/InputHeader';

const CreateUpdatePropFunnelsPage = () => {
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');

	const [propChallengesIds, setPropChallengesIds] = useState<Array<{ value: number; label: string }>>([]);
	const [fundedAccountTypesIds, setFundedAccountTypesIds] = useState<Array<{ value: number; label: string }>>([]);
	const [currentFirstChallengeTypeId, setCurrentFirstChallengeTypeId] = useState<{
		value: number;
		label: string;
	} | null>(null);
	const [currentSecondChallengeTypeId, setCurrentSecondChallengeTypeId] = useState<{
		value: number;
		label: string;
	} | null>(null);
	const [currentThirdChallengeTypeId, setCurrentThirdChallengeTypeId] = useState<{
		value: number;
		label: string;
	} | null>(null);
	const isEditing = editParam !== null;
	const funnelId = editParam ? decodeURIComponent(editParam!) : null;
	const [formState, setFormState] = useState<ICreatePropFunnelFormData | IUpdatePropFunnelFormData>({
		id: undefined,
		title: undefined,
		feeInUSD: undefined,
		enabled: true,
		firstChallengeTypeId: null,
		secondChallengeTypeId: null,
		thirdChallengeTypeId: null,
		fundedAccountTypeId: undefined,
	});

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);

			if (code !== 422) {
				toast.error(message);
			}
		}
	};

	const createFunnelMutation = useMutation((funnel: ICreatePropFunnelFormData) => createPropFunnel(funnel), {
		onSuccess: () => {
			toast.success(_t('prop-funnels.funnel-created-successfully'));
			history.push('/prop-funnels');
		},
		onError,
		retry: false,
	});

	const updateFunnelMutation = useMutation((funnel: IUpdatePropFunnelFormData) => updatePropFunnel(funnel, funnelId!), {
		onSuccess: () => {
			toast.success(_t('prop-funnels.funnel-updated-successfully'));
			history.push(`/prop-funnels/${funnelId}`);
		},
		onError,
		retry: false,
	});

	useQuery<Array<IPropChallenge>>(['prop-challenges'], () => getPropTradingChallenges(), {
		onSuccess: (data) =>
			setPropChallengesIds(
				data.map((challenge) => {
					return { value: challenge.id, label: challenge.title };
				})
			),
	});

	useQuery<Array<IFundedAccountType>>(['funded-accounts'], () => getFundedAccountTypes(), {
		onSuccess: (data) =>
			setFundedAccountTypesIds(
				data.map((accountTypes) => {
					return { value: accountTypes.id, label: accountTypes.title };
				})
			),
	});

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(createFunnelMutation.error || updateFunnelMutation.error, paramName);
		return error;
	};
	const currentFundedAccountTypeId = fundedAccountTypesIds.find((fat) => fat.value === formState.fundedAccountTypeId);
	useEffect(() => {
		if (formState.firstChallengeTypeId) {
			const firstChallengeTypeId =
				propChallengesIds.find((pac) => pac.value === formState.firstChallengeTypeId) ?? null;
			setCurrentFirstChallengeTypeId(firstChallengeTypeId);
		}
		if (formState.secondChallengeTypeId) {
			const secondChallengeTypeId =
				propChallengesIds.find((pac) => pac.value === formState.secondChallengeTypeId) ?? null;
			setCurrentSecondChallengeTypeId(secondChallengeTypeId);
		}

		if (formState.thirdChallengeTypeId) {
			const thirdChallengeTypeId =
				propChallengesIds.find((pac) => pac.value === formState.thirdChallengeTypeId) ?? null;
			setCurrentThirdChallengeTypeId(thirdChallengeTypeId);
		}
		setFormState({
			...formState,
			firstChallengeTypeId: currentFirstChallengeTypeId?.value ?? null,
			secondChallengeTypeId: currentSecondChallengeTypeId?.value ?? null,
			thirdChallengeTypeId: currentThirdChallengeTypeId?.value ?? null,
			fundedAccountTypeId: currentFundedAccountTypeId?.value,
		});
	}, [setCurrentFirstChallengeTypeId, setCurrentSecondChallengeTypeId, setCurrentThirdChallengeTypeId]);
	const { data: propFunnel } = useQuery<IPropFunnel | null, Error>(
		[`prop-funnel-${funnelId ?? 'unknown'}`],
		async () => {
			if (funnelId) {
				const data = await getPropFunnel(funnelId);
				return data.propFunnel;
			}
			return null;
		},
		{
			onSuccess: (data) => {
				if (data) {
					setFormState({
						title: data.title,
						feeInUSD: data.feeInUSD,
						enabled: data.enabled,
						firstChallengeTypeId: data.firstChallenge?.id ?? null,
						secondChallengeTypeId: data.secondChallenge?.id ?? null,
						thirdChallengeTypeId: data.thirdChallenge?.id ?? null,
						fundedAccountTypeId: data.fundedAccountType.id,
					});
				}
			},
			onError,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	const createOrUpdatePropFunnel = (e: React.FormEvent) => {
		e.preventDefault();
		if (isEditing) {
			const mutationData = {
				...formState,
				feeInUSD: Number(formState.feeInUSD),
			};
			updateFunnelMutation.mutate(mutationData);
		} else {
			const mutationData = {
				...(formState as ICreatePropFunnelFormData),
				feeInUSD: Number(formState.feeInUSD),
			};
			createFunnelMutation.mutate(mutationData);
		}
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const onNextIdChange = (newValue: any, arg: any) => {
		const name = arg.name;
		setFormState({ ...formState, [name]: newValue.value });
	};

	const onResetThirdChallenge = () => {
		setFormState({ ...formState, thirdChallengeTypeId: null });
	};
	const onResetSecondChallenge = () => {
		setFormState({ ...formState, secondChallengeTypeId: null });
	};

	const onResetFirstChallenge = () => {
		setFormState({ ...formState, firstChallengeTypeId: null });
	};

	const toggleIsEnabled = () => {
		setFormState({ ...formState, enabled: !formState.enabled });
	};

	const loading = createFunnelMutation.isLoading || updateFunnelMutation.isLoading;
	const getTitle = () => {
		if (!funnelId) {
			return _t.translate('prop-funnels.create-funnel');
		}
		return _t.translate('prop-funnels.edit-funnel');
	};

	const title = getTitle();

	return (
		<PageLayout title={title}>
			<CForm onSubmit={createOrUpdatePropFunnel}>
				<CRow>
					<CCol>
						<CCard style={{ overflow: 'visible' }}>
							<CCardBody>
								{!isEditing && (
									<div>
										{' '}
										<CFormGroup>
											<CLabel htmlFor="id">{_t(`prop-funnels.id`)}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id={'id'}
													name={'id'}
													placeholder={'DEMO_FUNNEL'}
													value={(formState as ICreatePropFunnelFormData).id || ''}
													onChange={handleInputChange}
													disabled={false}
													className="instrument-input-height"
												/>
											</CInputGroup>
											{findError('id') && <CLabel className="text-danger">{findError('id')}</CLabel>}
										</CFormGroup>
									</div>
								)}
								<CFormGroup>
									<CLabel htmlFor="title">{_t(`prop-funnels.title`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'title'}
											name={'title'}
											placeholder={'First funnel'}
											value={formState.title || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('title') && <CLabel className="text-danger">{findError('title')}</CLabel>}
								</CFormGroup>

								<CFormGroup>
									<CLabel htmlFor="fee-in-usd">{_t(`prop-funnels.fee-in-usd`)}</CLabel>

									<CInputGroup>
										<CInputGroupPrepend>
											<CInputGroupText>{'$'}</CInputGroupText>
										</CInputGroupPrepend>
										<CInput
											type="number"
											id="fee-in-usd"
											name="feeInUSD"
											placeholder="200"
											value={formState.feeInUSD || ''}
											onChange={handleInputChange}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('feeInUSD') && <CLabel className="text-danger">{findError('feeInUSD')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<InputHeader
										labelText={_t('prop-funnels.first-challenge-type-id')}
										resetFilter={onResetFirstChallenge}
									/>
									<CInputGroup>
										<CustomSelect
											className="w-100"
											options={propChallengesIds}
											name="firstChallengeTypeId"
											value={propChallengesIds.find((pac) => pac.value === formState.firstChallengeTypeId) ?? ''}
											whiteBackground
											onChange={onNextIdChange}
											fullWidth={true}
										/>
									</CInputGroup>
									{findError('firstChallengeTypeId') && (
										<CLabel className="text-danger">{findError('firstChallengeTypeId')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<InputHeader
										labelText={_t('prop-funnels.second-challenge-type-id')}
										resetFilter={onResetSecondChallenge}
									/>
									<CInputGroup>
										<CustomSelect
											className="w-100"
											options={propChallengesIds}
											name="secondChallengeTypeId"
											value={propChallengesIds.find((pac) => pac.value === formState.secondChallengeTypeId) ?? ''}
											whiteBackground
											onChange={onNextIdChange}
											fullWidth={true}
										/>
									</CInputGroup>
									{findError('secondChallengeTypeId') && (
										<CLabel className="text-danger">{findError('secondChallengeTypeId')}</CLabel>
									)}
								</CFormGroup>

								<CFormGroup>
									<InputHeader
										labelText={_t('prop-funnels.third-challenge-type-id')}
										resetFilter={onResetThirdChallenge}
									/>
									<CInputGroup>
										<CustomSelect
											className="w-100"
											options={propChallengesIds}
											name="thirdChallengeTypeId"
											value={propChallengesIds.find((pac) => pac.value === formState.thirdChallengeTypeId) ?? ''}
											whiteBackground
											onChange={onNextIdChange}
											fullWidth={true}
										/>
									</CInputGroup>
									{findError('thirdChallengeTypeId') && (
										<CLabel className="text-danger">{findError('thirdChallengeTypeId')}</CLabel>
									)}
								</CFormGroup>

								<CFormGroup>
									<CLabel htmlFor="funded-account-type-id">{_t('prop-funnels.funded-account-type-id')}</CLabel>
									<CInputGroup>
										<CustomSelect
											className="w-100"
											options={fundedAccountTypesIds}
											name="fundedAccountTypeId"
											value={currentFundedAccountTypeId}
											whiteBackground
											onChange={onNextIdChange}
											fullWidth={true}
										/>
									</CInputGroup>
									{findError('fundedAccountTypeId') && (
										<CLabel className="text-danger">{findError('fundedAccountTypeId')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t(`prop-funnels.enabled`)}</CLabel>
									<br></br>
									<CSwitch
										size="lg"
										shape="pill"
										color="primary"
										checked={formState.enabled}
										onChange={toggleIsEnabled}
									/>
									<br></br>
									{findError('is-demo-account') && (
										<CLabel className="text-danger">{findError('is-demo-account')}</CLabel>
									)}
								</CFormGroup>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default CreateUpdatePropFunnelsPage;
