import React, { FormEvent, useEffect, useState } from 'react';
import {
	CCard,
	CCardBody,
	CCol,
	CForm,
	CFormGroup,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CLabel,
	CRow,
} from '@coreui/react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import { errorWithCode, findErrorFromValidation } from '../../../helpers';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import PageLayout from '../../../components/PageLayout';
import toast from 'react-hot-toast';
import { ChallengeGroupsSelector } from '../helperComponents';
import {
	FundedAccountBehavior,
	ICreateFundedAccountTypeFormData,
	IFundedAccountType,
	IUpdateFundedAccountTypeFormData,
} from './types';
import {
	createFundedAccountType,
	getFundedAccountType,
	updateFundedAccountType,
} from '../../../services/BackendService';
import CustomSelect from '../../../components/CustomSelect';
import InputHeader from '../../../components/InputHeader';

const accountBehaviorOptions = [
	{
		label: FundedAccountBehavior.REGULAR,
		value: FundedAccountBehavior.REGULAR,
	},
	{
		label: FundedAccountBehavior.BINARY,
		value: FundedAccountBehavior.BINARY,
	},
];

const CreateUpdateFundedAccountTypePage = () => {
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');

	const isEditing = editParam !== null;
	const id = editParam ? Number(decodeURIComponent(editParam!)) : null;
	const [formState, setFormState] = useState<ICreateFundedAccountTypeFormData | IUpdateFundedAccountTypeFormData>({
		title: undefined,
		leverage: undefined,
		initialBalance: undefined,
		minTradingDays: undefined,
		maxInactivityDays: undefined,
		maxDailyDrawdownFactor: undefined,
		maxTotalDrawdownFactor: undefined,
		traderProfitShareFactor: undefined,
		accountGroup: undefined,
		accountCurrency: 'USD',
		behavior: FundedAccountBehavior.REGULAR,
		profitTargetFactor: undefined,
	});

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);

			if (code !== 422) {
				toast.error(message);
			}
		}
	};

	const createChallengeMutation = useMutation(
		(fundedAccountType: ICreateFundedAccountTypeFormData) => createFundedAccountType(fundedAccountType),
		{
			onSuccess: () => {
				toast.success(_t('funded-account-types.type-created-successfully'));
				history.push('/funded-account-types');
			},
			onError,
			retry: false,
		}
	);

	const updateChallengeMutation = useMutation(
		(fundedAccountType: IUpdateFundedAccountTypeFormData) => updateFundedAccountType(fundedAccountType, id!),
		{
			onSuccess: () => {
				toast.success(_t('funded-account-types.type-updated-successfully'));
				history.push(`/funded-account-types/${id}`);
			},
			onError,
			retry: false,
		}
	);

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(createChallengeMutation.error || updateChallengeMutation.error, paramName);
		return error;
	};

	const { data: fundedAccountType } = useQuery<IFundedAccountType | null, Error>(
		[`funded-account-${id ?? 'unknown'}`],
		async () => {
			if (id) {
				const data = await getFundedAccountType(id);
				return data.fundedAccountType;
			}
			return null;
		},
		{
			onError,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (fundedAccountType) {
			setFormState({ ...fundedAccountType });
		}
	}, [fundedAccountType]);

	const createOrUpdateFundedAccountType = (e: React.FormEvent) => {
		e.preventDefault();
		if (isEditing) {
			const mutationData = {
				...formState,
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance,
				minTradingDays: Number(formState.minTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				traderProfitShareFactor: formState.traderProfitShareFactor,
				maxDailyDrawdown: formState.maxDailyDrawdownFactor,
				maxTotalDrawdown: formState.maxTotalDrawdownFactor,
			};
			updateChallengeMutation.mutate(mutationData);
		} else {
			const mutationData = {
				...(formState as ICreateFundedAccountTypeFormData),
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance!,
				minTradingDays: Number(formState.minTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				traderProfitShareFactor: formState.traderProfitShareFactor!,
				maxDailyDrawdown: formState.maxDailyDrawdownFactor!,
				maxTotalDrawdown: formState.maxTotalDrawdownFactor!,
			};
			createChallengeMutation.mutate(mutationData);
		}
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const onSelectChange = (newValue: any) => {
		if (newValue !== formState.accountGroup) {
			setFormState({ ...formState, accountGroup: newValue });
		}
	};

	const onBehaviorChange = (newValue: any) => {
		setFormState({ ...formState, behavior: newValue.value });
	};

	const loading = createChallengeMutation.isLoading || updateChallengeMutation.isLoading;
	const getTitle = () => {
		if (!id) {
			return _t.translate('funded-account-types.create-type');
		}
		return _t.translate('funded-account-types.edit-type');
	};

	const title = getTitle();

	const onResetBehavior = () => {
		setFormState({ ...formState, behavior: FundedAccountBehavior.REGULAR });
	};

	return (
		<PageLayout title={title}>
			<CForm onSubmit={createOrUpdateFundedAccountType}>
				<CRow>
					<CCol>
						<CCard style={{ overflow: 'visible' }}>
							<CCardBody>
								<CFormGroup>
									<CLabel htmlFor="title">{_t(`funded-account-types.title`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'title'}
											name={'title'}
											placeholder={'First funded account type'}
											value={formState.title || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('title') && <CLabel className="text-danger">{findError('title')}</CLabel>}
								</CFormGroup>

								<CFormGroup>
									<CLabel htmlFor="initial-balance">{_t(`funded-account-types.initial-balance`)}</CLabel>

									<CInputGroup>
										<CInputGroupPrepend>
											<CInputGroupText>{'$'}</CInputGroupText>
										</CInputGroupPrepend>
										<CInput
											type="number"
											id="initial-balance"
											name="initialBalance"
											placeholder="200"
											value={formState.initialBalance || ''}
											onChange={handleInputChange}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('initialBalance') && (
										<CLabel className="text-danger">{findError('initialBalance')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="leverage">{_t(`funded-account-types.leverage`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'leverage'}
											name={'leverage'}
											placeholder={'1'}
											value={formState.leverage || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('leverage') && <CLabel className="text-danger">{findError('leverage')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="minimal-trading-days">{_t(`funded-account-types.min-trading-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'minimal-trading-days'}
											name={'minTradingDays'}
											placeholder={'1'}
											value={formState.minTradingDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('minTradingDays') && (
										<CLabel className="text-danger">{findError('minTradingDays')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-inactivity-days">{_t(`funded-account-types.max-inactivity-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-inactivity-days'}
											name={'maxInactivityDays'}
											placeholder={'1'}
											value={formState.maxInactivityDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxInactivityDays') && (
										<CLabel className="text-danger">{findError('maxInactivityDays')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="max-daily-drawdown">{_t(`funded-account-types.max-daily-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'max-daily-drawdown'}
											name={'maxDailyDrawdownFactor'}
											placeholder={'0.1'}
											value={formState.maxDailyDrawdownFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxDailyDrawdownFactor') && (
										<CLabel className="text-danger">{findError('maxDailyDrawdownFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="max-total-drawdown">{_t(`funded-account-types.max-total-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'max-total-drawdown'}
											name={'maxTotalDrawdownFactor'}
											placeholder={'0.2'}
											value={formState.maxTotalDrawdownFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxTotalDrawdownFactor') && (
										<CLabel className="text-danger">{findError('maxTotalDrawdownFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="max-total-drawdown">{_t(`funded-account-types.trader-profit-share-factor`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'max-total-drawdown'}
											name={'traderProfitShareFactor'}
											placeholder={'0.2'}
											value={formState.traderProfitShareFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('traderProfitShareFactor') && (
										<CLabel className="text-danger">{findError('traderProfitShareFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-group">{_t('funded-account-types.account-group')}</CLabel>
									<ChallengeGroupsSelector
										value={formState.accountGroup || ''}
										onChange={onSelectChange}
										currency={'USD'}
									/>
									{findError('accountGroup') && <CLabel className="text-danger">{findError('accountGroup')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<InputHeader
										labelText={_t('funded-account-types.behavior')}
										resetFilter={!isEditing ? onResetBehavior : undefined}
									/>
									<CInputGroup>
										<CustomSelect
											isDisabled={isEditing}
											className="w-100"
											options={accountBehaviorOptions}
											name="behavior"
											value={accountBehaviorOptions.find((opt) => opt.value === formState.behavior) ?? ''}
											whiteBackground
											onChange={onBehaviorChange}
											fullWidth={true}
										/>
									</CInputGroup>
									{findError('behavior') && <CLabel className="text-danger">{findError('behavior')}</CLabel>}
								</CFormGroup>
								{formState.behavior === FundedAccountBehavior.BINARY && (
									<CFormGroup>
										<CLabel htmlFor="profit-target-factor">{_t(`funded-account-types.profit-target-factor`)}</CLabel>
										<CInputGroup>
											<CInput
												type="number"
												id={'profit-target-factor'}
												name={'profitTargetFactor'}
												placeholder={'0.2'}
												value={formState.profitTargetFactor || ''}
												onChange={handleInputChange}
												className="instrument-input-height"
											/>
										</CInputGroup>
										{findError('profitTargetFactor') && (
											<CLabel className="text-danger">{findError('profitTargetFactor')}</CLabel>
										)}
									</CFormGroup>
								)}
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default CreateUpdateFundedAccountTypePage;
