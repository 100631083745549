import wintrado from './brands/wintrado/logo.svg';
import gfmarkets from './brands/gfmarkets/logo.svg';
import utocapital from './brands/utocapital/logo.png';
import propfundx from './brands/propfundx/logo.svg';

const logos = {
	wintrado,
	gfmarkets,
	utocapital,
  propfundx,
} as { [brand: string]: string };

export default logos[process.env.REACT_APP_BRAND!];
