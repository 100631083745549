export interface ICreateFundedAccountTypeFormData {
	title: string;
	leverage: number;
	initialBalance: number;
	minTradingDays: number;
	maxInactivityDays: number;
	maxDailyDrawdownFactor: number;
	maxTotalDrawdownFactor: number;
	traderProfitShareFactor: number;
	accountGroup: string;
	accountCurrency: string;
	behavior: FundedAccountBehavior;
	profitTargetFactor?: number;
}

export interface IUpdateFundedAccountTypeFormData {
	title?: string;
	leverage?: number;
	initialBalance?: number;
	minTradingDays?: number;
	maxInactivityDays?: number;
	maxDailyDrawdownFactor?: number;
	maxTotalDrawdownFactor?: number;
	traderProfitShareFactor?: number;
	accountGroup?: string;
	accountCurrency?: string;
	profitTargetFactor?: number;
	behavior: FundedAccountBehavior;
}

export interface IFundedAccountType {
	id: number;
	title: string;
	leverage: number;
	initialBalance: number;
	minTradingDays: number;
	maxInactivityDays: number;
	maxDailyDrawdownFactor: number;
	traderProfitShareFactor: number;
	maxTotalDrawdownFactor: number;
	accountGroup: string;
	accountCurrency: string;
	behavior: FundedAccountBehavior;
	profitTargetFactor?: number;
}

export enum FundedAccountBehavior {
	REGULAR = 'REGULAR',
	BINARY = 'BINARY',
}

export interface IGetFundedAccountTypeResponse {
	fundedAccountType: IFundedAccountType;
	canModify: boolean;
	canDelete: boolean;
}
