import React from 'react';
import { CCard, CCardBody, CCol, CLink, CRow } from '@coreui/react';
import { formatCurrency, formatDateTime } from '../../helpers';
import _t from 'counterpart';

interface LiveFeedEntryProps {
	eventData: ITransactionEvent;
	receivedEvent: ReceivedTransactionEvent;
}

export enum ReceivedTransactionEvent {
	DEPOSIT = 'DEPOSIT',
	WITHDRAWAL = 'WITHDRAWAL',
}

export interface ITransactionEvent {
	id: string;
	accountId: string;
	customerId: string;
	amount: number;
	currency: string;
	psp: string;
	createdAt: string;
}

const LiveFeedEntryTransactions: React.FC<LiveFeedEntryProps> = ({ receivedEvent, eventData }) => {
	return (
		<CCard className="mb-2">
			<CCardBody>
				<CRow>
					<CCol xs="12" md="6">
						<h6 className="card-title">
							{receivedEvent === ReceivedTransactionEvent.DEPOSIT
								? _t.translate('dashboard.deposit')
								: _t.translate('dashboard.withdrawal')}
							<CLink href={`/transactions/${eventData.id}`}>{` #${eventData.id.substring(0, 7)}`}</CLink>
						</h6>
					</CCol>
					<CCol xs="12" md="6">
						<div className="text-end dashboard-text-muted">{formatDateTime(eventData.createdAt)}</div>
					</CCol>
				</CRow>
				<CRow className="mt-1">
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.account-id')}</small>
							<div>{<CLink href={`/wallets/${eventData.accountId}`}>{eventData.accountId}</CLink>}</div>
						</div>
					</CCol>
					<CCol xs="6">
						<div>
							<small className="dashboard-text-muted">{_t.translate('dashboard.psp')}</small>
							<div>{eventData.psp}</div>
						</div>
					</CCol>
					<CCol xs="3">
						<div>
							<small className="dashboard-text-muted">{_t.translate('positions.amount')}</small>
							<div>{formatCurrency(eventData.amount, eventData.currency)}</div>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};

export default LiveFeedEntryTransactions;
