import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { getFundedAccountTypes } from '../../../services/BackendService';
import _t from 'counterpart';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import PageLayout from '../../../components/PageLayout';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import PaginationTable from '../../../components/PaginationTable';
import { formatCurrency } from '../../../helpers';
import { IFundedAccountType } from './types';

const FundedAccountTypesPage = () => {
	const history = useHistory();

	const fundedAccountTypesQuery = useQuery<Array<IFundedAccountType>>(['funded-accounts'], () =>
		getFundedAccountTypes()
	);

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'title', label: _t('funded-account-types.title'), sorter: false },
			{ key: 'initialBalance', label: _t('funded-account-types.initial-balance'), sorter: false },
			{ key: 'accountGroup', label: _t('funded-account-types.account-group'), sorter: false },
			{ key: 'behavior', label: _t('funded-account-types.behavior'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			id: ({ id }: IFundedAccountType) => <td>{id || '-'}</td>,
			title: ({ title }: IFundedAccountType) => <td>{title || '-'}</td>,
			initialBalance: ({ initialBalance }: IFundedAccountType) => <td>{formatCurrency(initialBalance) || '-'}</td>,
			behavior: ({ behavior }: IFundedAccountType) => <td>{behavior}</td>,
			accountGroup: ({ accountGroup }: IFundedAccountType) => <td>{accountGroup || '-'}</td>,
		}),
		[]
	);

	const rowClicked = useCallback(
		(fundedAccountType: IFundedAccountType) => {
			const { id } = fundedAccountType;
			history.push(`/funded-account-types/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history.push]
	);

	const linkToCreatePage = () => {
		history.push('/funded-account-types/form');
	};

	const onErrorRetry = () => {
		fundedAccountTypesQuery.refetch();
	};

	if (fundedAccountTypesQuery.isIdle) {
		return <Loading />;
	}

	if (fundedAccountTypesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('funded-account-types.page-title')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={linkToCreatePage} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={fundedAccountTypesQuery.data || []}
								loading={fundedAccountTypesQuery.isLoading}
								pages={0}
								pagination
								onRowClicked={rowClicked}
								clickableRows
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default FundedAccountTypesPage;
