export interface ITransactionCustomer {
	id: string;
	name: string;
	kycStatus: string;
	country: string;
}

export enum TransactionType {
	Deposit = 'DEPOSIT',
	Withdrawal = 'WITHDRAWAL',
	Bonus = 'BONUS',
	DepositBonus = 'DEPOSIT_BONUS',
	Fee = 'FEE',
	CoinsExchange = 'COINS_EXCHANGE',
	WalletTransfer = 'WALLET_TRANSFER',
	CommissionPayout = 'COMMISSION_PAYOUT',
	PropPurchase = 'PROP_PURCHASE',
}

export enum TransactionStatus {
	// Created request
	Created = 'created',
	// Pending response from PSP (deposits) or approval from backoffice admin (withdrawals)
	Pending = 'pending',
	// Successful
	Successful = 'successful',
	// Canceled by user interaction
	Canceled = 'canceled',
	// Failed because e.g. payment provider has declined it
	Failed = 'failed',
	// Technical error
	Error = 'error',
}

export enum WithdrawalStatus {
	Approved = 'approved',
	Rejected = 'rejected',
	Refunded = 'refunded',
}

export interface ITransaction {
	id: string;
	type: TransactionType;
	customer: ITransactionCustomer;
	netAmountInUSD: number;
	createdAt: string;
	status: TransactionStatus;
	method: string | null;
	provider: string | null;
	providerRef: string | null;
	amount: number;
	comment: string | null;
	currency: string;
	wallet?: number;
	creditedAt?: string | null;
	walletAddress: string | null;
	adminId: string | null;
	ipAddress?: string | null;
	conversionFrom?: string | null;
	conversionFromAmount?: string | null;
	conversionTo?: string | null;
	conversionToAmount?: string | null;
	conversionRate?: string | null;
	conversionSource?: string | null;
	conversionDate?: string | null;
}

export interface IWithdrawal {
	id: string;
	amount: number;
	currency: string;
	createdAt: string;
	customer: ITransactionCustomer;
	creditedAt: string;
	comment: string;
	status: string;
	walletAddress: string | null;
	provider: string;
	adminId: string | null;
}
